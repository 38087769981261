/* Общие стили */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  transition: background-color 0.3s ease; /* Плавный переход */
}

/* Стиль для контейнера */
.App {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff; /* Для светлой темы */
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease; /* Плавные изменения */
}

/* Заголовок */
h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333; /* Для светлой темы */
  transition: color 0.3s ease;
}

/* Дата выбора */
.date-range-picker {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.date-range-picker button {
  margin: 0 5px;
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.date-range-picker button:hover {
  background-color: #0056b3;
}

/* Стиль для списка данных */
ul {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.weather-item {
  background-color: #fafafa; /* Для светлой темы */
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.weather-item:hover {
  transform: scale(1.02);
}

.weather-item h2 {
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: #333; /* Для светлой темы */
}

.weather-item p {
  font-size: 1rem;
  color: #555; /* Для светлой темы */
}

.weather-item .timestamp {
  font-size: 0.9rem;
  color: #888;
  margin-top: 10px;
}

/* Кнопка */
button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Мобильная адаптация */
@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .weather-item {
    flex-direction: row;
    justify-content: space-between;
  }

  .weather-item p {
    font-size: 0.9rem;
  }
}

/* Темная тема */
body.dark-mode {
  background-color: #1a1a1a;
  color: white;
}

.App.dark-mode {
  background-color: #333; /* Для темной темы */
}

h1.dark-mode {
  color: #fff; /* Для темной темы */
}

.weather-item.dark-mode {
  background-color: #444; /* Для темной темы */
}

.weather-item h2.dark-mode {
  color: #fff; /* Для темной темы */
}

.weather-item p.dark-mode {
  color: #ddd; /* Для темной темы */
}

button.dark-mode {
  background-color: #555; /* Для темной темы */
}

button.dark-mode:hover {
  background-color: #777; /* Для темной темы */
}

/* Плавные переходы */
body, .App, .weather-item {
  transition: background-color 0.3s ease, color 0.3s ease;
}
